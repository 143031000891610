import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import { Link, FormattedMessage } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/Seo"

const Project = ({ count, node }) => {
  const { frontmatter, fields } = node;
  const { thumbnailone, selling, description, title, logo } = frontmatter;
  const projectClass = `project transition-fade ${count % 2 === 0 ? 'project-inv' : ''}`;

  return (
    <article className={projectClass}>
      {thumbnailone && (
        <Link to={fields.slug}>
          <BackgroundImage
            fluid={thumbnailone.childImageSharp.fluid}
            className="project-img"
          >
            {console.log("frontmatter", frontmatter)}
            {selling && (
              <div className="project-selling">
                <span>
                  <FormattedMessage id="selling" />
                </span>
              </div>
            )}
            <div className="description">{description}</div>
          </BackgroundImage>
          <div style={{ display: "flex", alignItems: "center", padding: "0 .5em" }}>
            {logo &&
              <div style={{ margin: "0.4em .5em 0 0 " }} className="project-logo">
                <img style={{ maxHeight: "5em" }} src={logo.childImageSharp.fluid.src} alt={title} />
              </div>
            }
            <h4 className="project-title-link">{title}</h4>
          </div>

        </Link>
      )}
    </article>
  );
};

const ProjectsPage = ({ data, pageContext }) => {
  const projects = data.allMdx.edges;
  const image = data.bgImage.childImageSharp.fluid;

  const [selectedCategory, setSelectedCategory] = useState("residential");
  const [older, setOlder] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState([]);

  useEffect(() => {
    setFilteredProjects(projects.filter(({ node }) => {
      const { lang, older: nodeOlder, category } = node.frontmatter;
      return older ? lang === pageContext.intl.language && category == selectedCategory : (lang === pageContext.intl.language && !nodeOlder && category == selectedCategory);
    }));
  }, [selectedCategory, older]);



  return (
    <Layout className="transition-fade">
      <SEO title="Projects" />

      <div id="projects-page">
        <div className="project-content-header header">
          <h1 className="project-content-title">
            <FormattedMessage id="projects" />
          </h1>
          <div className="categories-container">
            <div>
              <div id="categories">
                <input
                  type="radio"
                  id="radio-1"
                  name="categories"
                  checked={selectedCategory === "residential"}
                  onChange={() => setSelectedCategory("residential")}
                />
                <label class="tab" for="radio-1">
                  <FormattedMessage id="residential" />
                </label>
                <input
                  type="radio"
                  id="radio-2"
                  name="categories"
                  checked={selectedCategory === "industrial"}
                  onChange={() => setSelectedCategory("industrial")}
                />
                <label class="tab" for="radio-2">
                  <FormattedMessage id="industrial" />
                </label>
                <span class="glider"></span>
              </div>
            </div>
          </div>
        </div>

        <div className="projects-feed">
          {filteredProjects.map(({ node }, index) => (
            <Project
              key={node.fields.slug}
              count={index + 1}
              node={node}
              postClass="post"
            />
          ))}
        </div>

        {!older && filteredProjects?.length ? (
          <div className="older_btn">
            <button className="btn" onClick={() => setOlder(true)}>
              <FormattedMessage id="show_older" />
            </button>
          </div>
        ) : null}
        <BackgroundImage fluid={image} className="about-bg">
          &nbsp;
        </BackgroundImage>
      </div>
    </Layout >
  );
};

const projectsQuery = graphql`
  query {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/projects/" } }
      sort: { fields: [frontmatter___position], order: ASC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            description
            tags
            lang
            category
            older
            selling
            position
            thumbnailone {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            logo {
              childImageSharp {
                fluid(maxWidth: 250) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    bgImage: file(relativePath: { eq: "plan_bg.png" }) {
      id
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site: site {
      siteMetadata {
        title
        keywords
        slogen
        social {
          twitter
        }
      }
    }
  }
`


const ProjectsContainer = (props) => {
  const data = useStaticQuery(projectsQuery);
  return <ProjectsPage {...props} data={data} />;
};

export default ProjectsContainer;
